import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "menu", "overlay" ]
  static values = {
    open: Boolean,
  }

  open() {
    this.openValue = true
  }

  close() {
    this.openValue = false
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  handleHoverOpen() {
    this.timeout = setTimeout(this.open.bind(this), 250);
  }

  handleHoverClose() {
    this.timeout = setTimeout(this.close.bind(this), 250);
  }

  clearHover() {
    clearTimeout(this.timeout);
  }

  openValueChanged() {
    this.menuTarget.classList.toggle("sidebar--closed", !this.openValue);
    this.overlayTarget.classList.toggle("overlay--visible", this.openValue);
  }
}
