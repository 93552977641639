import { Controller } from 'stimulus';

export default class extends Controller {
   static targets = [ "input" ]
    static values = {
        editable: Boolean,
    }
    handleAmountEditable(e) {
        this.editableValue = e.target.value
    }

    editableValueChanged() {
        this.inputTarget.disabled = this.editableValue;
        if (this.editableValue) {
            this.inputTarget.value = '';
        }
    }

    formatAmount() {
        const amount = parseFloat(this.inputTarget.value)
        this.inputTarget.value = amount.toFixed(2)
    }
}
