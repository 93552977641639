import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['switch'];
  static values = {
    open: Boolean,
  }

  toggle() {
    this.switchTarget.classList.toggle('form__switch--selected');
  }
}
