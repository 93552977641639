import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "list", "arrow", "container" ]
  static values = { open: Boolean }

  connect() {
    if (!this.openValue) {
      this.toggle();
    }
  }

  toggle() {
    this.arrowTarget.classList.toggle("arrow--close")
    this.listTarget.classList.toggle('close')
    this.containerTarget.classList.toggle('applications__container--closed')
  }
}
