import { Controller } from 'stimulus';
import consumer from "../../channels/consumer";

const channelName = "PaymentSessionStatusChannel"

const translations = {
  'en': {
    'success': "Payment successful. Redirecting to merchant...",
    'failure': "Payment failed. Redirecting to merchant...",
    'initiated': "Payment initiated. Redirecting to merchant..."
  },
  'de': {
    'success': "Zahlung erfolgreich. Weiterleitung an Händler...",
    'failure': "Zahlung fehlgeschlagen. Weiterleitung an Händler...",
    'initiated': "Zahlung eingeleitet. Weiterleitung an Händler..."
  }
}

export default class extends Controller {
  static values = {
    paymentSessionId: String,
    returnToMerchantUrl: String,
    timeout: Number,
    locale: String
  }

  static targets = ["status", "button", "spinner"]


  connect() {
    const paymentSesssionId = this.paymentSessionIdValue
    const statusElement = this.statusTarget;
    const returnToMerchantUrl = this.returnToMerchantUrlValue;
    const timeoutMiliseconds = this.timeoutValue * 1000;
    const locale = this.localeValue;

    setTimeout(this.noUpdateReceived.bind(this), timeoutMiliseconds)

    this.channel = consumer.subscriptions.create(
      { channel: channelName, id: paymentSesssionId },
      {
        connected() {
          this.perform('refresh')
        },

        received(data) {
          const { state } = data;
          switch (state) {
            case "sent":
              statusElement.innerText = translations[locale]["success"]
              window.location.assign(returnToMerchantUrl);
              break;
            case "initiated":
              statusElement.innerText = translations[locale]["initiated"]
              window.location.assign(returnToMerchantUrl);
              break;
            case "failed":
              statusElement.innerText = translations[locale]["failure"]
              window.location.assign(returnToMerchantUrl);
              break;
          }
        },
      }
    )
  }

  noUpdateReceived() {
    this.buttonTarget.style.visibility = 'visible'
    this.spinnerTarget.style.visibility = 'hidden'

    consumer.disconnect()
  }

  disconnect() {
    consumer.disconnect();
  }
}
