import { Controller } from "stimulus";

const checkoutSupportedWidths = {
  small: "280px",
  medium: "480px",
  large: "640px",
};

export default class extends Controller {
  static targets = ["link", "container", "root"];
  static values = {
    open: Boolean,
  };

  initialize() {
    this.handleLargeSize();
  }

  handleLargeSize() {
    this.containerTarget.classList.remove("size-small");
    this.containerTarget.classList.remove("size-medium");
    this.containerTarget.classList.add("size-large");
    this.containerTarget.style.maxWidth = checkoutSupportedWidths["large"];
  }

  handleMediumSize() {
    this.containerTarget.classList.remove("size-large");
    this.containerTarget.classList.remove("size-small");
    this.containerTarget.classList.add("size-medium");
    this.containerTarget.style.maxWidth = checkoutSupportedWidths["medium"];
  }

  handleSmallSize() {
    this.containerTarget.classList.remove("size-large");
    this.containerTarget.classList.remove("size-medium");
    this.containerTarget.classList.add("size-small");
    this.containerTarget.style.maxWidth = checkoutSupportedWidths["small"];
  }
}
