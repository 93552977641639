import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["frame", "closeButton", "form"];
  static values = { open: Boolean };

  connect() {
    this.handleEscCloseBound = this.escClose.bind(this);
    this.observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "src"
        ) {
          if (this.frameTarget.getAttribute("src") !== null) {
            this.openValue = true;
            history.pushState(
              { turbo_frame_history: true },
              "",
              this.element.getAttribute("src")
            );
          } else {
            this.openValue = false;
          }
        }
      });
    });

    this.observer.observe(this.frameTarget, { attributes: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  openValueChanged() {
    if (this.openValue) {
      this.element.classList.add("modal--open");
      document.body.addEventListener('keyup', this.handleEscCloseBound)
    } else {
      document.body.removeEventListener('keyup', this.handleEscCloseBound)
      this.element.classList.remove("modal--open");
      this.frameTarget.innerHTML = "<div class='loading-spinner'></div>";
      this.frameTarget.removeAttribute("src");
    }
  }

  close() {
    this.openValue = false;
  }

  escClose(event) {
    if (event.key === "Escape") this.close();
  }

  submitEnd(e) {
    e.preventDefault();
    e.stopPropagation();

    const response = e.detail.fetchResponse.response;
    const location = e.detail.fetchResponse.location;

    if (response.ok) {
      this.close();
    }

    if (response.redirected) {
      Turbo.visit(location.href);
    }
  }

  closeOnClickOutside(e) {
    if (!this.frameTarget.contains(e.target)) {
      this.close();
    }
  }
}
