import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "button", "image", "size", "name"];
  static values = { existingUpload: Boolean }

  connect() {
    this.reader = new FileReader();
    this.reader.onload = (e) => { (this.imageTarget.src = e.target.result) };
  }

  triggerFileSelect() {
    this.inputTarget.click();
  }

  cancel() {
    this.inputTarget.value = '';
    this.element.classList.remove("form__image_picker__container--selected");
    this.buttonTarget.classList.remove("form__submit--disabled");
  }

  dragOver(e) {
    e.preventDefault();
    this.element.classList.add("form__image_picker__container--focus");
    this.buttonTarget.classList.add("form__submit--disabled");
  }

  dragLeave(e) {
    e.preventDefault();
    this.element.classList.remove("form__image_picker__container--focus");
    this.buttonTarget.classList.remove("form__submit--disabled");
  }

  showPreview(file) {
    this.sizeTarget.innerText = this.formatBytes(file.size);
    this.nameTarget.innerText = file.name
    this.reader.readAsDataURL(file);
    this.element.classList.add("form__image_picker__container--selected");
  }

  onChange(e) {
    this.element.classList.remove("form__image_picker__container--error");
    this.showPreview(e.target.files[0])
  }

  dragDrop(e) {
    e.preventDefault();
    this.element.classList.remove("form__image_picker__container--focus");
    const regex = /(jpg|png|jpeg)$/g
    if (e.dataTransfer.files[0].name.toLowerCase().match(regex)) {
      this.element.classList.remove("form__image_picker__container--error");
      this.inputTarget.files = e.dataTransfer.files;
      this.showPreview(e.dataTransfer.files[0])
    } else {
      this.element.classList.add("form__image_picker__container--error");
      this.buttonTarget.classList.remove("form__submit--disabled");
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
