import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "list", "header", "root"]
  static values = {
    open: Boolean,
  }

  connect() {
    this.handleClickBound = this.handleClick.bind(this);
  }

  open() {
    this.openValue = true
  }

  close() {
    this.openValue = false
  }

  handleClick(e) {
    if(!this.rootTarget.contains(e.target)){
      this.close()
    }
  }

  handleOpen() {
    this.listTarget.classList.add('open')
    this.headerTarget.classList.add('sidebar__header--selected');
    document.body.addEventListener('click', this.handleClickBound)
  }

  handleClose() {
    this.listTarget.classList.remove('open');
    this.headerTarget.classList.remove('sidebar__header--selected');
    document.body.removeEventListener('click', this.handleClickBound)
  }


  toggle() {
    this.openValue = !this.openValue;
  }

  openValueChanged() {
    if(this.openValue) {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  }
}
