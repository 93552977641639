import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [ 'editable', 'block', 'initialReference', 'label', 'placeholder', 'description' ]
    static values = {
        mode: String,
    }

    handleReferenceMode(e) {
        this.modeValue = e.target.value
    }

    modeValueChanged() {
        this.editableTarget.value = (this.modeValue === "open")
        this.initialReferenceTarget.required = (this.modeValue === 'fixed');

        if (this.modeValue !== 'fixed') {
            this.initialReferenceTarget.value = '';
        }
        if (this.modeValue !== 'open') {
            this.labelTarget.value = '';
            this.placeholderTarget.value = '';
            this.descriptionTarget.value = '';
        }
        this.blockTargets.forEach((block) => {
            const visible = block.dataset.blockName == this.modeValue
            block.style.display = visible ? 'block' : 'none'    
        }, this)
    }
}


