import { Controller } from 'stimulus';

const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}   

export default class extends Controller {
  static values = { fieldName: String, validateAs: String, labelName: String }
  
  selectTextInElement = debounce(el =>el.select(), 0)

  serializeInputs(inputs) {
    return inputs.map(e => e.value).join('')
  }
  serializedValueToISODate(serialized) {
    let [day, month, year] = [serialized.slice(0,2), serialized.slice(2,4), serialized.slice(4,8)]
    return `${year}-${month}-${day}`  
  }
  validate(value) {
    if (this.validateAsValue === 'date') {
      if (value.length === this.inputs.length) {
        const ISODate = this.serializedValueToISODate(value)
        const parsedDate = Date.parse(ISODate)
        if (isNaN(parsedDate)) {
          this.element.classList.add('split-number-input--has-error')
          this.errorMessage.textContent = `${this.labelNameValue} must be a valid date`
        } else if (parsedDate > Date.now()) {
          this.element.classList.add('split-number-input--has-error')
          this.errorMessage.textContent = `${this.labelNameValue} must be in the past`
        }
      }
    }
  }
  bindEvents() {
    this.inputs.forEach( (element, index) => {
      element.addEventListener('focus', e => {
        if (e.target.value === '' && !this.inputs[0].isSameNode(e.target)) {
          this.inputs.find(e => e.value === '').focus()
        } else {
          this.selectTextInElement(e.target)
        }
        this.element.classList.add('split-number-input--focused')
      })
      element.addEventListener('blur', e => {
        this.element.classList.remove('split-number-input--focused')
      })
      element.addEventListener('keydown', e => {
        if (e.keyCode === 8 && e.target.value === '') this.inputs[Math.max(0, index-1)].focus()
      })
      element.addEventListener('input', e => {
        const filteredValue = e.target.value.replace(/\D/g, '')

        if (filteredValue.length !== e.target.value.length) {
          this.element.classList.add('split-number-input--has-error')
          this.errorMessage.textContent = `${this.labelNameValue} must only contain numbers`
        } else {
          this.element.classList.remove('split-number-input--has-error')
        }

        const [first, ...rest] = filteredValue
        e.target.value = first ?? '' 
  
        const wasCharacterAdded = first !== undefined
        const isLastInput = index === this.inputs.length - 1
  
        if (wasCharacterAdded && !isLastInput) {
          this.inputs[index + 1].focus()
          if (rest.length) {
            this.inputs[index + 1].value = rest.join('')
            this.inputs[index + 1].dispatchEvent(new Event('input'))
          } 
        }

        const serializedValue = this.serializeInputs(this.inputs)

        this.validate(serializedValue)
  
        this.valueInput.value = serializedValue
      })
    })
  }
  connect() {
    this.inputs = [...this.element.querySelectorAll('input')]
    this.valueInput = document.querySelector(`[name="${this.fieldNameValue}"]`)
    this.errorMessage = this.element.querySelector('.split-number-input__error')

    if (!this.inputs.length) return;

    this.bindEvents();
  
    if (this.valueInput.value !== "") {
      this.inputs[0].value = this.valueInput.value
      this.inputs[0].dispatchEvent(new Event('input'))
    }
  }
}
