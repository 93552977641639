import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "message" ]

  connect() {
    this.messageTarget.addEventListener('animationend', this.removeMessage.bind(this))
  }

  removeMessage() {
    this.messageTarget.remove();
  }
}
