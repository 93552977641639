import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['block']
    static values = {
        active: String,
    }

    handleChange(e) {
        this.activeValue = e.target.value
    }

    activeValueChanged() {
        this.blockTargets.forEach((block) => {
          if (block.dataset.blockName == this.activeValue) {
              block.style.display = 'block'
              block.style.visibility = 'visible'
              block.querySelectorAll('input').forEach((input) => input.disabled = false);
            } else {
              block.style.display = 'none'
              block.style.visibility = 'hidden'
              block.querySelectorAll('input').forEach((input) => input.disabled = true);
            }
        }, this)
    }
}


